<template>
  <div>
    <a-card title="采购询价详情">
      <a-button slot="extra" v-print="'#printContent'" ghost icon="printer" style="margin-right: 8px" type="primary">
        打印
      </a-button>
      <a-button slot="extra" ghost icon="left" type="primary" @click="$router.go(-1)">返回</a-button>

      <section id="printContent">
        <a-spin :spinning="queryLoading">
          <img id="barcode" style="float: right" />
          <a-descriptions bordered>
            <a-descriptions-item label="采购询价单号">
              {{ dataItem.number }}
            </a-descriptions-item>
            <a-descriptions-item label="供应商">
              {{ dataItem.supplier_items.map((supplierItem) => supplierItem.name).join(";") }}
            </a-descriptions-item>
            <a-descriptions-item label="状态">
              {{ dataItem.status_display }}
            </a-descriptions-item>
            <a-descriptions-item label="截止时间">
              {{ dataItem.closing_time }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ dataItem.remark }}
            </a-descriptions-item>
            <a-descriptions-item label="创建人">
              {{ dataItem.creator_name }}
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              {{ dataItem.create_time }}
            </a-descriptions-item>
            <a-descriptions-item label="附件">
              <div v-for="fileItem in dataItem.purchase_inquiry_file_items" :key="fileItem.id">
                <a :href="fileItem.file" target="_blank" style="margin-right: 16px">{{ fileItem.name }}</a>
              </div>
            </a-descriptions-item>
          </a-descriptions>
          <a-divider orientation="left" style="margin-top: 30px">物料信息</a-divider>
          <a-table :columns="columns" :dataSource="goodsItems" :pagination="false" rowKey="id" size="small" />
        </a-spin>
      </section>
    </a-card>
  </div>
</template>

<script>
import { purchaseInquiryOrderDetail } from "@/api/purchasing";

export default {
  data() {
    return {
      queryLoading: false,
      dataItem: {},
      goodsItems: [],

      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "物料编号",
          dataIndex: "goods_number",
        },
        {
          title: "物料名称",
          dataIndex: "goods_name",
        },
        {
          title: "物料条码",
          dataIndex: "goods_barcode",
        },
        {
          title: "物料规格",
          dataIndex: "goods_spec",
        },
        {
          title: "物料单位",
          dataIndex: "goods_unit",
        },
        {
          title: "采购数量",
          dataIndex: "purchase_quantity",
        },
      ],
    };
  },
  methods: {
    initData() {
      this.queryLoading = true;
      purchaseInquiryOrderDetail({ id: this.$route.query.id })
        .then((data) => {
          this.dataItem = data;
          this.goodsItems = data.purchase_inquiry_goods_items;
        })
        .finally(() => {
          this.queryLoading = false;
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style></style>
